import { useEffect } from "react"

import getQueryParam from "src/utils/getQueryParam"
import { getCookieValue } from "src/utils/i18n/TranslationProvider"

const cookieKey = "partnerStackKey"

export function usePartnerstackTracking() {
  const encodedKey = "gspk"
  const queryParam = getQueryParam(encodedKey)
  const isSSR = typeof window === "undefined"

  useEffect(() => {
    if (!isSSR && queryParam) {
      try {
        const maxAge = 60 * 60 * 24 * 7 // save cookie for 1 week
        const partnerStackKey = window.atob(queryParam)
        document.cookie = `${cookieKey}=${partnerStackKey}; path=/; domain=minut.com; max-age=${maxAge}`
      } catch (err) {
        console.error(err)
      }
    }
  }, [queryParam, isSSR])
}

export function getPartnerStackKey() {
  const partnerStackKey = getCookieValue(cookieKey)
  return partnerStackKey ?? undefined
}
