export default function getQueryParam(param) {
  try {
    const params = new URLSearchParams(window.location.search)
    return params.get(param)
  } catch (err) {
    return null
  }
}

export function hasQueryParam(param) {
  try {
    const params = new URLSearchParams(window.location.search)
    return params.has(param)
  } catch (err) {
    return false
  }
}
