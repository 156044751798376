import React from "react"

import fetchGeoLocationData from "src/utils/fetchGeoLocationData"

import { languageKeys } from "./languages"
import { getTranslationString, interpolateString } from "./translate"

const InitialState = {
  lang: languageKeys.EN,
  t: (key, interpolations, options) => "",
  setLang: () => {},
}

export const TranslationContext = React.createContext(InitialState)

export function useTranslate() {
  return React.useContext(TranslationContext)
}

export function TranslationProvider({ children }) {
  const [lang, setLang] = React.useState(
    getStoredLanguage() || InitialState.lang
  )

  React.useEffect(() => {
    // Check if language selection has already been stored
    const storedLanguage = getStoredLanguage()

    if (storedLanguage) {
      setLang(storedLanguage)
      return
    }

    // Otherwise fetch language selection
    fetchGeoLocationData().then((data) => {
      const language = mapCountryCodeToLanguage(data?.countryCode)
      storeLanguage(language)
      setLang(language)
    })
  }, [])

  function handleSetLanguage(language) {
    storeLanguage(language)
    setLang(language)
  }

  function t(key, interpolations, options) {
    const rawTranslation = getTranslationString(key, lang)

    const interpolatedTranslation = interpolateString(
      rawTranslation,
      interpolations
    )

    if (options?.rawString) {
      return interpolatedTranslation
    }

    return (
      <span dangerouslySetInnerHTML={{ __html: interpolatedTranslation }} />
    )
  }

  return (
    <TranslationContext.Provider
      value={{ lang, t, setLang: handleSetLanguage }}
    >
      {children}
    </TranslationContext.Provider>
  )
}

function mapCountryCodeToLanguage(countryCode = "us") {
  const _countryCode = countryCode?.toLocaleLowerCase() || "us"

  const language = {
    fr: languageKeys.FR,
    it: languageKeys.IT,
    de: languageKeys.DE,
    es: languageKeys.ES,
    mx: languageKeys.ES,
    cz: languageKeys.CS,
    pt: languageKeys.PT,
  }[_countryCode]

  return language ?? languageKeys.EN
}

const isSSR = typeof window === "undefined"

function getStoredLanguage() {
  if (isSSR) {
    return null
  }

  try {
    // prefer to use the currentLang cookie if set since that is used on the main website
    const currentLangCookieValue = getCookieValue("currentLang")

    return currentLangCookieValue
      ? currentLangCookieValue
      : window.localStorage.getItem("language")
  } catch (err) {
    console.error(err)
  }
}

function storeLanguage(language) {
  if (isSSR) {
    return null
  }

  try {
    const maxAge = 60 * 60 * 24 * 30
    document.cookie = `currentLang=${language}; path=/; domain=minut.com; max-age=${maxAge}`
    window.localStorage.setItem("language", language)
  } catch (err) {
    console.error(err)
  }
}

// from https://stackoverflow.com/a/15724300
export function getCookieValue(name) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  return parts.length === 2 ? parts.pop().split(";").shift() : null
}
